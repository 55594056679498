<template>
  <div class="home">
    <div class="row-me row-center">
      <el-image :src="user.avatar" :preview-src-list="[user.avatar]" fit="cover" class="img60" hide-on-click-modal>
        <template #error>
          <img src="../assets/img/header.png" slot="error" class="img60 objFitCover" draggable="false">
        </template>
      </el-image>
      <div class="column-me mL30">
        <div class="row-me row-center">
          <div>用户：{{ user.nickName }}</div>
          <div class="mL50">{{ user.phonenumber }}</div>
          <div class="mL50" v-if="user.dept">{{ user.dept.deptName }}</div>
        </div>
        <div class="mT10">账号：{{ user.userName }}</div>
      </div>
    </div>

    <div class="row-me mT30">
      <div class="flex1 card1 column-me">
        <div class="flex1 mT16">今日预警次数</div>
        <div class="row-me row-center mB16">
          <div class="flex1">
            <div class="font22 font-bold">{{ topItem.dayCount }}</div>
            <div class="row-me row-center font12 mT10">
              <div>较昨天</div>
              <div class="row-me row-center" v-if="topItem.dayCount>=topItem.yesDayCount1">
                <div class="mL10 text-red">{{ topItem.dayCount - topItem.yesDayCount1 }}</div>
                <img src="../assets/img/icon_ss.png" class="img20 objScaleDown" draggable="false">
              </div>
              <div class="row-me row-center" v-else>
                <div class="mL10 text-green">{{ topItem.yesDayCount1 - topItem.dayCount }}</div>
                <img src="../assets/img/icon_xj.png" class="img20 objScaleDown" draggable="false">
              </div>
            </div>
          </div>
          <img src="../assets/img/img_tubiao1.png" class="w120 h60 objScaleDown" draggable="false">
        </div>
      </div>
      <div class="flex1 card2 column-me">
        <div class="flex1 mT16">已绑定设备总数</div>
        <div class="row-me row-center mB16">
          <div class="flex1">
            <div class="font22 font-bold">{{ topItem.equipmentCount }}</div>
            <div class="row-me row-center font12 mT10">
              <div>较昨天</div>
              <div class="row-me row-center" v-if="topItem.equipmentCount>=topItem.yesequipmentCount1">
                <div class="mL10 text-red">{{ topItem.equipmentCount - topItem.yesequipmentCount1 }}</div>
                <img src="../assets/img/icon_ss.png" class="img20 objScaleDown" draggable="false">
              </div>
              <div class="row-me row-center" v-else>
                <div class="mL10 text-green">{{ topItem.yesequipmentCount1 - topItem.equipmentCount }}</div>
                <img src="../assets/img/icon_xj.png" class="img20 objScaleDown" draggable="false">
              </div>
            </div>
          </div>
          <img src="../assets/img/img_tubiao3.png" class="w120 h60 objScaleDown" draggable="false">
        </div>
      </div>
      <div class="flex1 card3 column-me">
        <div class="flex1 mT16">系统设备总数</div>
        <div class="row-me row-center mB16">
          <div class="flex1">
            <div class="font22 font-bold">{{ topItem.counts }}</div>
            <div class="row-me row-center font12 mT10">
              <div>较昨天</div>
              <div class="row-me row-center" v-if="topItem.counts>=topItem.ysecounts1">
                <div class="mL10 text-red">{{ $method.toFixed(topItem.counts - topItem.ysecounts1, 2) }}</div>
                <img src="../assets/img/icon_ss.png" class="img20 objScaleDown" draggable="false">
              </div>
              <div class="row-me row-center" v-else>
                <div class="mL10 text-green">{{ $method.toFixed(topItem.ysecounts1 - topItem.counts, 2) }}</div>
                <img src="../assets/img/icon_xj.png" class="img20 objScaleDown" draggable="false">
              </div>
            </div>
          </div>
          <img src="../assets/img/img_tubiao2.png" class="w120 h60 objScaleDown" draggable="false">
        </div>
      </div>
      <div class="flex1 card4 column-me">
        <div class="flex1 mT16">系统用户总数</div>
        <div class="row-me row-center mB16">
          <div class="flex1">
            <div class="font22 font-bold">{{ topItem.userCount }}</div>
            <div class="row-me row-center font12 mT10">
              <div>较昨天</div>
              <div class="row-me row-center" v-if="topItem.userCount>=topItem.yseuserCount1">
                <div class="mL10 text-red">{{ $method.toFixed(topItem.userCount - topItem.yseuserCount1, 2) }}</div>
                <img src="../assets/img/icon_ss.png" class="img20 objScaleDown" draggable="false">
              </div>
              <div class="row-me row-center" v-else>
                <div class="mL10 text-green">{{ $method.toFixed(topItem.yseuserCount1 - topItem.userCount, 2) }}</div>
                <img src="../assets/img/icon_xj.png" class="img20 objScaleDown" draggable="false">
              </div>
            </div>
          </div>
          <img src="../assets/img/img_tubiao4.png" class="w120 h60 objScaleDown" draggable="false">
        </div>
      </div>
      <div class="flex1 card5 column-me">
        <div class="flex1 mT16">今日拉取数据总数</div>
        <div class="row-me row-center mB16">
          <div class="flex1">
            <div class="font22 font-bold">{{ topItem.pushDayCount }}</div>
            <div class="row-me row-center font12 mT10">
              <div>较昨天</div>
              <div class="row-me row-center" v-if="topItem.pushDayCount>=topItem.pushYesCount">
                <div class="mL10 text-red">{{ $method.toFixed(topItem.pushDayCount - topItem.pushYesCount, 2) }}</div>
                <img src="../assets/img/icon_ss.png" class="img20 objScaleDown" draggable="false">
              </div>
              <div class="row-me row-center" v-else>
                <div class="mL10 text-green">{{ $method.toFixed(topItem.pushYesCount - topItem.pushDayCount, 2) }}</div>
                <img src="../assets/img/icon_xj.png" class="img20 objScaleDown" draggable="false">
              </div>
            </div>
          </div>
          <img src="../assets/img/img_tubiao5.png" class="w120 h60 objScaleDown" draggable="false">
        </div>
      </div>
      <div class="flex1 card6 column-me">
        <div class="flex1 mT16">预警率（%）</div>
        <div class="row-me row-center mB16">
          <div class="flex1">
            <div class="font22 font-bold">{{ topItem.warningDay }}</div>
            <div class="row-me row-center font12 mT10">
              <div>较昨天</div>
              <div class="row-me row-center" v-if="topItem.warningDay>=topItem.warningYes">
                <div class="mL10 text-red">{{ $method.toFixed(topItem.warningDay - topItem.warningYes, 2) }}</div>
                <img src="../assets/img/icon_ss.png" class="img20 objScaleDown" draggable="false">
              </div>
              <div class="row-me row-center" v-else>
                <div class="mL10 text-green">{{ $method.toFixed(topItem.warningYes - topItem.warningDay, 2) }}</div>
                <img src="../assets/img/icon_xj.png" class="img20 objScaleDown" draggable="false">
              </div>
            </div>
          </div>
          <img src="../assets/img/img_tubiao6.png" class="w120 h60 objScaleDown" draggable="false">
        </div>
      </div>
    </div>

    <div class="row-me mT30">
      <!--      <div class="column-me flex1 mR30">-->
      <!--        <div class="h60 row-me row-center border-bottom">-->
      <!--          <div class="blue-label"></div>-->
      <!--          <div class="font22 mL15 flex1">用户增长趋势</div>-->
      <!--          <div class="w150 mR20">-->
      <!--            <el-date-picker type="month" placeholder="选择日期" value-format="YYYY-MM" format="YYYY-MM"-->
      <!--                            style="width: 100%;" :clearable="false" :editable="false" v-model="selectDate1"-->
      <!--                            @change="getUserData">-->
      <!--            </el-date-picker>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="h400">-->
      <!--          <chartUserData ref="chartUserData"></chartUserData>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="column-me flex1">
        <div class="h60 row-me row-center border-bottom">
          <div class="blue-label"></div>
          <div class="font22 mL15 flex1">护航预警统计</div>
        </div>
        <div class="h400">
          <warningData ref="warningData"></warningData>
        </div>
      </div>
    </div>

    <div class="row-me mT30">
      <div class="column-me flex1">
        <div class="h60 row-me row-center border-bottom">
          <div class="blue-label"></div>
          <div class="font22 mL15 flex1">预警统计</div>
          <div class="w150 mR20">
            <el-date-picker type="month" placeholder="选择日期" value-format="YYYY-MM" format="YYYY-MM"
                            style="width: 100%;" :clearable="false" :editable="false" v-model="selectDate2"
                            @change="getWarningFourData">
            </el-date-picker>
          </div>
        </div>
        <div class="h400">
          <warningFourData ref="warningFourData"></warningFourData>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chartUserData from '@/components/homeCharts/chartUserData.vue'
import warningData from '@/components/homeCharts/warningData.vue'
import warningFourData from '@/components/homeCharts/warningFourData.vue'

export default {
  components: {
    chartUserData,
    warningData,
    warningFourData
  },
  data() {
    return {
      selectDate1: '',
      selectDate2: '',
      user: {},
      topItem: {},
    }
  },
  // 侦听器
  watch: {},
  // 计算属性
  computed: {},
  // html加载完成之前
  created() {
    this.selectDate1 = this.$method.getNowYearMonth()
    this.selectDate2 = this.$method.getNowYearMonth()
    this.getUserInfo()
  },
  // html加载完成后执行。
  mounted() {
    this.$nextTick(() => {
      this.getData()
    })
  },
  // 事件方法执行
  methods: {
    //获取用户信息
    getUserInfo() {
      this.$get('/system/user/profile', {}).then((res) => {
        if (res) {
          this.user = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    getData() {
      this.getTopNumber()
      this.getUserData()
      this.getWarningData()
      this.getWarningFourData()
    },
    getTopNumber() {
      this.$get('/works/dateStatistics', {}).then((res) => {
        if (res) {
          this.topItem = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    getUserData() {
      this.$get('/works/userTendency', {
        time: this.selectDate1,
      }).then((res) => {
        if (res) {
          let xData = []
          let yData = []
          xData = res.data.map(item => {
            let arr = item.dateTime.split('-')
            return arr[2] + '日'
          })
          yData = res.data.map(item => {
            return item.counts
          })
          this.$refs.chartUserData.initChart(xData, yData)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    getWarningData() {
      this.$get('/works/escortTendency', {}).then((res) => {
        if (res) {
          let xData = []
          let yData = []
          xData = res.data.map(item => {
            let arr = item.dateTime.split('-')
            return arr[1] + '月' + arr[2] + '日'
          })
          yData = res.data.map(item => {
            return item.counts
          })
          this.$refs.warningData.initChart(xData, yData)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    getWarningFourData() {
      this.$get('/works/warningTendency', {
        time: this.selectDate2
      }).then((res) => {
        if (res) {
          let xData = []
          let yData1 = []
          let yData2 = []
          let yData3 = []
          let yData4 = []
          xData = res.data.map(item => {
            let arr = item.dateTime.split('-')
            return arr[2] + '号'
          })
          yData1 = res.data.map(item => {
            return item.counts1
          })
          yData2 = res.data.map(item => {
            return item.counts2
          })
          yData3 = res.data.map(item => {
            return item.counts3
          })
          yData4 = res.data.map(item => {
            return item.counts4
          })
          this.$refs.warningFourData.initChart(xData, yData1, yData2, yData3, yData4)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .card1 {
    height: 133px;
    background: linear-gradient(180deg, #F0F9FE 0%, #E5F4FE 100%);
    border-radius: 4px;
    padding: 0 16px;
    margin-right: 20px;
  }

  .card2 {
    height: 133px;
    background: linear-gradient(180deg, #F5FFF2 0%, #E4FEEE 100%);
    border-radius: 4px;
    padding: 0 16px;
    margin-right: 20px;
  }

  .card3 {
    height: 133px;
    background: linear-gradient(180deg, #F3F9FC 0%, #E5F5FD 100%);
    border-radius: 4px;
    padding: 0 16px;
    margin-right: 20px;
  }

  .card4 {
    height: 133px;
    background: linear-gradient(180deg, #F6F6FF 0%, #ECECFF 100%);
    border-radius: 4px;
    padding: 0 16px;
    margin-right: 20px;
  }

  .card5 {
    height: 133px;
    background: linear-gradient(180deg, #FFFAEA 0%, #FFF7DE 100%);
    border-radius: 4px;
    padding: 0 16px;
    margin-right: 20px;
  }

  .card6 {
    height: 133px;
    background: linear-gradient(180deg, #FFF4F4 0%, #FFEAEA 100%);
    border-radius: 4px;
    padding: 0 16px;
  }

  .blue-label {
    width: 6px;
    height: 24px;
    background: #468CFD;
    border-radius: 0px 6px 6px 0px;
  }
}
</style>
